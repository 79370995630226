import React from 'react';

import Container from '../Container';

import './header.css';

import { CategoryIcon, DateIcon } from '../Icons/icons';

import loadComponents from '../Loadable';
import loadLibrary from '../Loadable/library';
import TitleH1 from '../Title';

const Img = loadLibrary('gatsby-image');

const Link = loadComponents('link');

export default function SubpageHeader({ title, image, date, categories }) {
  return (
    <div
      className="pageHeader"
      style={{
        padding: `60px 0 75px`,
        display: `flex`,
        alignItems: `center`,
        position: `relative`,
        minHeight: image && `420px`,
      }}
    >
      {image && (
        <>
          <div
            style={{
              position: `absolute`,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: `rgba(0,0,0,0.4)`,
              zIndex: 5,
            }}
          />
          <Img
            image={image}
            alt={title}
            itemProp="image"
            style={{
              position: `absolute`,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          />
        </>
      )}

      <Container style={{ zIndex: 10 }}>
        {date && (
          <span
            style={{
              color: `#fff`,
              display: `flex`,
              alignItems: `center`,
              gap: `30px`,
              marginBottom: `45px`,
            }}
          >
            <DateIcon color="#fff" /> Posted: {date}
          </span>
        )}
        <TitleH1 line={1.6} margin="0px" colour={image && '#fff'}>
          {title}
        </TitleH1>
        {categories && categories.nodes.length > 0 && (
          <div
            className="headerCategories"
            style={{
              display: `flex`,
              alignItems: `center`,
              gap: `30px`,
              marginTop: `45px`,
            }}
          >
            <CategoryIcon color="#fff" />
            {categories.nodes.map((cat, index) => (
              <span key={cat.name}>
                {index !== 0 ? ', ' : ''}
                <Link to={cat.link} style={{ color: `#fff` }}>
                  {cat.name}
                </Link>
              </span>
            ))}
          </div>
        )}
      </Container>
    </div>
  );
}
